import React from 'react';
import Seo from '../components/Seo';

export default function IndexPage() {
  return (
    <article>
      <Seo title="Harriet Gibsone's official website" />
      <h2>The official website of Harriet Gibsone</h2>
      <p>Harriet Gibsone is a freelance writer and former Guardian editor based in London.</p>
    </article>
  );
}
